import React, { useContext, useEffect, useState } from 'react';
import './DashboardView.css';
import Header from '../../components/Header/Header';
import Usermenu from '../../components/Usermenu/Usermenu';
import Sidebar from '../../components/Sidebar/Sidebar';
import Coursetable from '../../components/Coursetable/Coursetable';
import Footer from '../../components/Footer/Footer';
//import Copilot from "../../components/Copilot/Copilot";
import { NavLink, Outlet } from 'react-router-dom';
import {
  api,
  AppDataContext,
  Copilot,
  getUserMetadataAPI,
  getUserName,
} from '@iblai/ibl-web-react-common';
import CopilotLauncher from '../../components/CopilotLauncher/CopilotLauncher';
import CopilotCloser from '../../components/CopilotCloser/CopilotCloser';
import { defaultSPALogoData } from '../../hooks/helpers';
import logoImg from '../../assets/images/ibl-logos--copy_1.png';
import logoImg500 from '../../assets/images/ibl-logos--copy_1-p-500.png';
import logoImg800 from '../../assets/images/ibl-logos--copy_1-p-800.png';
import logoImg1080 from '../../assets/images/ibl-logos--copy_1-p-1080.png';
import logoImg1600 from '../../assets/images/ibl-logos--copy_1-p-1600.png';
import logoImg2000 from '../../assets/images/ibl-logos--copy_1-p-2000.png';
import SearchBar from '../../components/SearchBar/SearchBar';
import useTenant from '../../hooks/useTenant';
import useTourTooltips from '../../hooks/useTourTooltips';
import { TourProvider } from '@reactour/tour';
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";

const SHOW_MENTOR = process.env.REACT_APP_IBL_ENABLE_MENTOR !== 'false';

const DashboardView = () => {
  const { tenantMetaData, appData, setAppData, notification, notificationRef } = useContext(AppDataContext);
  const {} = useTenant();
  const { handleStartTour } = useTourTooltips();
  const [mentorDisplayEnabled, setMentorDisplayEnabled] = useState(false);
  //const {mentorDisplayEnabled, setMentorDisplayEnabled} = useContext(AppDataContext)

  useEffect(() => {
    if (SHOW_MENTOR) {
      let mentorAIEnabled =
        tenantMetaData?.enable_sidebar_ai_mentor_display !== false;
      if (mentorAIEnabled) {
        mentorAIEnabled = false;
        if (
          typeof appData.userMetaData === 'object' &&
          Object.keys(appData.userMetaData).length > 0
        ) {
          //META DATA EXIST
          mentorAIEnabled =
            appData?.userMetaData?.public_metadata
              ?.enable_sidebar_ai_mentor_display !== false;
        } else {
          getUserMetadataAPI(getUserName(), (metadata) => {
            mentorAIEnabled =
              metadata?.public_metadata?.enable_sidebar_ai_mentor_display !==
              false;
            setAppData({
              ...appData,
              userMetaData: metadata,
            });
          });
        }
      }
      setMentorDisplayEnabled(mentorAIEnabled);
    }
  }, [tenantMetaData, appData?.userMetaData?.public_metadata]);

  return (
      <>
        <SlAlert
            ref={notificationRef}
            variant={notification?.success ? 'success' : 'danger'}
            duration={5000}
            closable
        >
          <SlIcon
              slot="icon"
              name={notification?.success ? 'check2-circle' : 'exclamation-octagon'}
          />
          <strong>{notification?.msg}</strong>
        </SlAlert>
        <span>
      <span className="af-view">
        <div className="body">
          <div className="w-layout-hflex fullpage-container">
            <div
                className={`w-layout-vflex webpage-container no-padding-mobile ${!mentorDisplayEnabled ? 'ai-mentor-not-displayed' : ''}`}
            >
              <Header></Header>
              <div className="mob_nav">
                <div className="div-block">
                  <div className="mob_nav_trigger">
                    <div className="text-block-134 fa_icon"></div>
                  </div>
                  <NavLink className="link-block-17 w-inline-block" to="/">
                    <img
                        alt=""
                        className="image mob-logo"
                        loading="lazy"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 34vw, (max-width: 991px) 202.1640625px, 100vw"
                        src={defaultSPALogoData.logo || logoImg}
                        srcSet={
                          defaultSPALogoData.logo
                              ? ''
                              : `${logoImg500} 500w, ${logoImg800} 800w, ${logoImg1080} 1080w, ${logoImg1600} 1600w, ${logoImg2000} 2000w`
                        }
                    />
                  </NavLink>
                  <div className="s-input">
                    <SearchBar />
                  </div>
                  <Usermenu></Usermenu>
                </div>
                <div className="div-block-355 left_menu_mob">
                  <div className="div-block-3">
                    <div className="div-block-2 mt-reduced">
                      <a className="ai-skills-btn w-inline-block" href="#">
                        <div className="text-block-130">AI Skills</div>
                      </a>
                    </div>
                    <div className="div-block-2">
                      <a className="link-block-18 w-inline-block" href="#">
                        <div className="text-block-130">Downloads</div>
                      </a>
                    </div>
                    <div className="div-block-2 hidden">
                      <div className="notifications_trigger">
                        <a
                            className="link-block-18 w-inline-block"
                            href="notifications.html"
                        >
                          <div className="text-block-130">Notifications</div>
                        </a>
                      </div>
                    </div>
                    <div className="div-block-2 nav-field hidden">
                      <div className="roles">
                        <img
                            alt=""
                            className="user-img nb"
                            loading="lazy"
                            src="images/setting_1setting.png"
                        />
                        <div className="text-block-130 sp">Roles</div>
                        <div className="text-block fa_icon arrow"></div>
                        <div className="div-block-164 profile">
                          <div className="div-block-165">
                            <div className="text-block-104">Ashley Miller</div>
                            <div className="text-block-104 em">
                              ashley@gmail.com
                            </div>
                          </div>
                          <a className="link-block-11 w-inline-block" href="#">
                            <div className="text-block-106">Overview</div>
                          </a>
                          <a className="link-block-11 w-inline-block" href="#">
                            <div className="text-block-106">Profile</div>
                          </a>
                          <a className="link-block-11 w-inline-block" href="#">
                            <div className="text-block-106">Log Out</div>
                          </a>
                        </div>
                        <div className="roles_popup">
                          <div className="div-block-195">
                            <a className="link-2" href="#">
                              Admin
                            </a>
                          </div>
                          <div className="div-block-195">
                            <a className="link-2" href="#">
                              Instructor
                            </a>
                          </div>
                          <div className="div-block-195 last">
                            <a className="link-2" href="#">
                              Editor
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Usermenu></Usermenu>
                  </div>
                </div>
              </div>
              <div className="main">
                <Sidebar></Sidebar>
                <div className="mob_sidebar_overlay"></div>
                <div className="main-content">
                  <Outlet />
                </div>
                <Footer></Footer>
              </div>
            </div>
            {mentorDisplayEnabled && (
                <Copilot
                    isWhiteThemed={false}
                    backgroundColor={'#282c34'}
                    theme="dark"
                ></Copilot>
            )}
            {/*<CopilotCloser/>*/}
          </div>
          {/*<CopilotLauncher/>*/}
        </div>
      </span>
    </span>
      </>

  );
};

export default DashboardView;
