import {getTenant, getUserName} from "@iblai/ibl-web-react-common";

export const getCourseAPI = (limit = 12, offset = "", search = "", isProgram=false, useTenant=true, isPathway=false, isSkills=false, usePersonnalizedCatalog=true) => {
  const tenant = getTenant()
  const params = {
    ...(useTenant ? {
      tenant:tenant
    } : {} ),
    content:isProgram ? "programs" : 'courses',
    ...(offset ? {
      offset:offset
    } : {}),
    limit,
    ...(search ? {
      query:search
    } : {})
  }
  const queryParam = new URLSearchParams({
    ...params,
    ...(isPathway ? {
      content:"pathways"
    } : {}),
    ...(isSkills ? {
      content:"skills"
    } : {}),

  }).toString()
  const url = `${process.env.REACT_APP_IBL_DM_URL}/api/search/${usePersonnalizedCatalog ? `personalized-catalog/${getUserName()}` : "catalog" }/?${queryParam}`;
  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      return data || [];
    })
    .catch((error) => {
      console.error('Error fetching contents:', error);
      return [];
    });
};


