import React, {useContext, useRef, useState} from 'react';
import {getTenant, isJSON} from "@iblai/ibl-web-react-common";
import {
    createCourseAPI, createCourseExtraSection,
    createCourseSection, deleteCourseAPI, setScormCreationAPI,
    updateCourseMetaDataAPI,
    uploadFileToCourseStudio, videoFileUploadAPI
} from "./course";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
import timezone from 'dayjs/plugin/timezone';
import {getYouTubeVideoId, isYouTubeVideoUrl} from "../helpers";
import {useNavigate} from "react-router-dom";
import { AppDataContext } from '@iblai/ibl-web-react-common';
dayjs.extend(utc);
dayjs.extend(timezone);

const useCourseCreation = () => {
    const {notification, setNotification, notificationRef} = useContext(AppDataContext)
    const navigate = useNavigate()
    const TABS = [
        //"Settings",
        "Settings",
        "Content",
    ]
    const [currentTab, setCurrentTab] = useState(TABS[0])
    const isLastTab = () => {
        const currentTabIndex = TABS.findIndex(pre=>pre===currentTab)
        return currentTabIndex === (TABS.length - 1)
    }

    const goToNextTab = () => {
        const currentTabIndex = TABS.findIndex(pre=>pre===currentTab)
        const isTheLastTab = currentTabIndex === (TABS.length - 1)
        setCurrentTab(isTheLastTab ? currentTab : TABS[currentTabIndex+1])
        if(isTheLastTab){
            handleFormSubmit()
        }
    }

    const switchContentType = (e, contentType='video') => {
        e.preventDefault()
        setFields((old)=>({
            ...old,
            uploadContentType:contentType
        }))
    }


    const [submitting, setSubmitting] = useState(false)
    const defaultFields  = {
        display_name:"",
        courseKey:"",
        //META DATA FIELDS
        description:"",
        duration:"",
        tags:[],//["topic1", "topic2"]
        topics:[],//["topic1", "topic2"]
        course_image_asset_path:"",//corresponds to url under studio image upload response
        course_image_name:"",//corresponds to display_name
        //COURSE SECTION FIELDS
        section_title:"",
        section_locator:"",
        sub_section_title:"",
        sub_section_locator:"",
        unit_title:"",
        unit_locator:"",
        video_locator:"",
        //COURSE CONTENT
        video_url:"",
        //AUTO POPULATED
        start_date:"",
        //TO BE DELETED BEFORE POST
        file:"",
        scorm:"",
        uploadContentType:'video',
        video_file:""
    }
    const [fields, setFields] = useState(defaultFields)
    const defaultStudioImageUploadResponse = {
        "asset": {
            "display_name": "",
            "content_type": "",
            "date_added": "",
            "url": "",
            "external_url": "",
            "portable_url": "",
            "thumbnail": "",
            "locked": false,
            "static_full_url": "",
            "id": ""
        },
        "msg": ""
    }
    const defaultVideoFileUploadResponse = {
        file_url:"",
        name:""
    }
    const [imageCardUploadResponse, setImageCardUploadResponse] = useState(defaultStudioImageUploadResponse)
    const [scormFileUploadResponse, setScormFileUploadResponse] = useState(defaultStudioImageUploadResponse)
    const [videoFileUploadResponse, setVideoFileUploadResponse ] = useState(defaultVideoFileUploadResponse)

    const handleFormSubmit = async () => {

        if(!(fields?.display_name && fields?.description && fields?.duration
        )){
            setCurrentTab(TABS[0])
            setNotification({
                success: false,
                msg: "Fill out all required fields."
            })
            return
        }
        if(!(fields?.section_title && fields?.sub_section_title &&
        fields?.unit_title
        )){
            setCurrentTab(TABS[1])
            setNotification({
                success: false,
                msg: "Fill out all required fields."
            })
            return
        }
        if((fields.uploadContentType==="video" && !fields.video_url) || (fields.uploadContentType==="scorm" && !fields.scorm)
        || (fields.uploadContentType==="video_file" && !fields.video_file)
        ){
            setCurrentTab(TABS[1])
            setNotification({
                success: false,
                msg: "Upload a content type."
            })
            return
        }
        setSubmitting(true)
        let videoURL = fields?.video_url
        const currentTenantData = localStorage.getItem('current_tenant')
        const org = isJSON(currentTenantData) ? JSON.parse(currentTenantData)?.org : null
        const courseData = await createCourseAPI({
            org,
            display_name:fields.display_name
        })
        if(!courseData?.course_key){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during initial course creation",
                success: false
            })
            return
        }
        const courseKey = courseData.course_key
        let parsedFileUploadData = {};
        if(fields.file){
            const formData = new FormData()
            formData.append('file', fields.file)
            const fileUploadData = await uploadFileToCourseStudio(formData, courseKey)
            parsedFileUploadData = isJSON(fileUploadData) ? JSON.parse(fileUploadData) : {}
            if(!parsedFileUploadData?.asset?.id){
                setNotification({
                    msg: "An error occurred during image card upload. Course creation still in progress...",
                    success: false
                })
            }
        }
        const courseKeySplit = courseKey.split('+');
        const courseID = courseKeySplit[1];
        const courseRun = courseKeySplit[2];
        const courseMetaDataFields = {
            org,
            "course_id": courseID,
            course_image_name:parsedFileUploadData?.asset?.display_name ?? "",
            course_image_asset_path:parsedFileUploadData?.asset?.url ?? "",
            "run": courseRun,
            "language": "en",
            "start_date": dayjs().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            "end_date": null,
            "enrollment_start": null,
            "enrollment_end": null,
            "certificate_available_date": null,
            "certificates_display_behavior": "end",
            "syllabus": null,
            "title": "",
            "subtitle": "",
            "duration": fields.duration,
            "description": fields.description,
            "tags": fields.tags.map((_tag)=>(_tag?.label)),
            "topics": fields.topics.map((_topic)=>(_topic?.label)),
            "short_description": "",
            "about_sidebar_html": "",
            "intro_video": null,
            "effort": null,
            "license": null,
            "pre_requisite_courses": [],
            "entrance_exam_enabled": "",
            "entrance_exam_id": "",
            "entrance_exam_minimum_score_pct": "50",
            "self_paced": false,
            "learning_info": [],
            "instructor_info": {
                "instructors": []
            },
            "slug": null,
            "platform_key": getTenant(),
            "course_key": courseKey
        }
        const updatedCourseMetaDataFields = await updateCourseMetaDataAPI(courseMetaDataFields)
        if(!updatedCourseMetaDataFields?.course_key){
            await deleteCourseAPI(courseKey)
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course metadata settings update.",
                success: false
            })
            return
        }
        const sectionData = await createCourseSection({
            "parent_locator": `block-v1:${org}+${courseID}+${courseRun}+type@course+block@course`,
            "category": "chapter",
            "display_name": fields?.section_title
        })
        if(!sectionData?.locator){
            await deleteCourseAPI(courseKey)
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course section creation.",
                success: false
            })
            return
        }
        const subSectionData = await createCourseSection({
            "parent_locator": sectionData?.locator,
            "category": "sequential",
            "display_name": fields?.sub_section_title
        })
        if(!subSectionData?.locator){
            await deleteCourseAPI(courseKey)
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course sub-section creation.",
                success: false
            })
            return
        }
        const unitData = await createCourseSection({
            "parent_locator": subSectionData?.locator,
            "category": "vertical",
            "display_name": fields?.unit_title
        })
        if(!unitData?.locator){
            await deleteCourseAPI(courseKey)
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course unit creation.",
                success: false
            })
            return
        }
        //YOUTUBE URL UPLOAD OR SCORM
        switch(fields.uploadContentType){
            case "scorm":
                const scormData = {
                    "display_name": {
                        "value": fields?.display_name,
                        "display_name": "Course Display Name",
                        "help": "Enter the name of the course as it should appear in the course list.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "course_edit_method": {
                        "value": "Studio",
                        "display_name": "Course Editor",
                        "help": "Enter the method by which this course is edited (\"XML\" or \"Studio\").",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "days_early_for_beta": {
                        "value": null,
                        "display_name": "Days Early for Beta Users",
                        "help": "Enter the number of days before the start date that beta users can access the course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "due": {
                        "value": null,
                        "display_name": "Due Date",
                        "help": "Enter the date by which problems are due.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "matlab_api_key": {
                        "value": null,
                        "display_name": "Matlab API key",
                        "help": "Enter the API key provided by MathWorks for accessing the MATLAB Hosted Service. This key is granted for exclusive use in this course for the specified duration. Do not share the API key with other courses. Notify MathWorks immediately if you believe the key is exposed or compromised. To obtain a key for your course, or to report an issue, please contact moocsupport@mathworks.com",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "max_attempts": {
                        "value": null,
                        "display_name": "Maximum Attempts",
                        "help": "Enter the maximum number of times a student can try to answer problems. By default, Maximum Attempts is set to null, meaning that students have an unlimited number of attempts for problems. You can override this course-wide setting for individual problems. However, if the course-wide setting is a specific number, you cannot set the Maximum Attempts for individual problems to unlimited.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "relative_weeks_due": {
                        "value": null,
                        "display_name": "Number of Relative Weeks Due By",
                        "help": "Enter the number of weeks the problems are due by relative to the learner's enrollment date",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "rerandomize": {
                        "value": "never",
                        "display_name": "Randomization",
                        "help": "Specify the default for how often variable values in a problem are randomized. This setting should be set to \"never\" unless you plan to provide a Python script to identify and randomize values in most of the problems in your course. Valid values are \"always\", \"onreset\", \"never\", and \"per_student\".",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "show_reset_button": {
                        "value": false,
                        "display_name": "Show Reset Button for Problems",
                        "help": "Enter true or false. If true, problems in the course default to always displaying a 'Reset' button. You can override this in each problem's settings. All existing problems are affected when this course-wide setting is changed.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "showanswer": {
                        "value": "finished",
                        "display_name": "Show Answer",
                        "help": "Specify when the Show Answer button appears for each problem. Valid values are \"always\", \"answered\", \"attempted\", \"closed\", \"finished\", \"past_due\", \"correct_or_past_due\", \"after_all_attempts\", \"after_all_attempts_or_correct\", \"attempted_no_past_due\", and \"never\".",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "static_asset_path": {
                        "value": "",
                        "display_name": "Static Asset Path",
                        "help": "Enter the path to use for files on the Files & Uploads page. This value overrides the Studio default, c4x://.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "use_latex_compiler": {
                        "value": false,
                        "display_name": "Enable LaTeX Compiler",
                        "help": "Enter true or false. If true, you can use the LaTeX templates for HTML components and advanced Problem components.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "video_speed_optimizations": {
                        "value": true,
                        "display_name": "Enable video caching system",
                        "help": "Enter true or false. If true, video caching will be used for HTML5 videos.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "xqa_key": {
                        "value": null,
                        "display_name": "XQA Key",
                        "help": "This setting is not currently supported.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "source_file": {
                        "value": null,
                        "display_name": "LaTeX Source File Name",
                        "help": "Enter the source file name for LaTeX.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "advanced_modules": {
                        "value": [
                            "scorm"
                        ],
                        "display_name": "Advanced Module List",
                        "help": "Enter the names of the advanced modules to use in your course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "advertised_start": {
                        "value": null,
                        "display_name": "Course Advertised Start",
                        "help": "Enter the text that you want to use as the advertised starting time frame for the course, such as \"Winter 2018\". If you enter null for this value, the start date that you have set for this course is used.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "allow_proctoring_opt_out": {
                        "value": false,
                        "display_name": "Allow Opting Out of Proctored Exams",
                        "help": "Enter true or false. If this value is true, learners can choose to take proctored exams without proctoring. If this value is false, all learners must take the exam with proctoring. This setting only applies if proctored exams are enabled for the course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "allow_public_wiki_access": {
                        "value": false,
                        "display_name": "Allow Public Wiki Access",
                        "help": "Enter true or false. If true, students can view the course wiki even if they're not enrolled in the course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "announcement": {
                        "value": null,
                        "display_name": "Course Announcement Date",
                        "help": "Enter the date to announce your course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "banner_image": {
                        "value": "images_course_image.jpg",
                        "display_name": "Course Banner Image",
                        "help": "Edit the name of the banner image file. You can set the banner image on the Settings & Details page.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "catalog_visibility": {
                        "value": "both",
                        "display_name": "Course Visibility In Catalog",
                        "help": "Defines the access permissions for showing the course in the course catalog. This can be set to one of three values: 'both' (show in catalog and allow access to about page), 'about' (only allow access to about page), 'none' (do not show in catalog and do not allow access to an about page).",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "cert_html_view_enabled": {
                        "value": true,
                        "display_name": "Certificate Web/HTML View Enabled",
                        "help": "If true, certificate Web/HTML views are enabled for the course.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "cert_html_view_overrides": {
                        "value": {},
                        "display_name": "Certificate Web/HTML View Overrides",
                        "help": "Enter course-specific overrides for the Web/HTML template parameters here (JSON format)",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "cert_name_long": {
                        "value": "",
                        "display_name": "Certificate Name (Long)",
                        "help": "Use this setting only when generating PDF certificates. Between quotation marks, enter the long name of the type of certificate that students receive when they complete the course. For instance, \"Certificate of Achievement\".",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "cert_name_short": {
                        "value": "",
                        "display_name": "Certificate Name (Short)",
                        "help": "Use this setting only when generating PDF certificates. Between quotation marks, enter the short name of the type of certificate that students receive when they complete the course. For instance, \"Certificate\".",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "certificates_show_before_end": {
                        "value": false,
                        "display_name": "Certificates Downloadable Before End",
                        "help": "Enter true or false. If true, students can download certificates before the course ends, if they've met certificate requirements.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "cosmetic_display_price": {
                        "value": 0,
                        "display_name": "Cosmetic Course Display Price",
                        "help": "The cost displayed to students for enrolling in the course. If a paid course registration price is set by an administrator in the database, that price will be displayed instead of this one.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "course_image": {
                        "value": "images_course_image.jpg",
                        "display_name": "Course About Page Image",
                        "help": "Edit the name of the course image file. You must upload this file on the Files & Uploads page. You can also set the course image on the Settings & Details page.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": true
                    },
                    "course_survey_name": {
                        "value": null,
                        "display_name": "Pre-Course Survey Name",
                        "help": "Name of SurveyForm to display as a pre-course survey to the user.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "course_survey_required": {
                        "value": false,
                        "display_name": "Pre-Course Survey Required",
                        "help": "Specify whether students must complete a survey before they can view your course content. If you set this value to true, you must add a name for the survey to the Course Survey Name setting above.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "course_wide_css": {
                        "value": [],
                        "display_name": "Course-wide Custom CSS",
                        "help": "Enter CSS resource URLs you want to be loaded globally throughout the course pages.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "course_wide_js": {
                        "value": [],
                        "display_name": "Course-wide Custom JS",
                        "help": "Enter Javascript resource URLs you want to be loaded globally throughout the course pages.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "create_zendesk_tickets": {
                        "value": true,
                        "display_name": "Create Zendesk Tickets For Suspicious Proctored Exam Attempts",
                        "help": "Enter true or false. If this value is true, a Zendesk ticket will be created for suspicious attempts.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "css_class": {
                        "value": "",
                        "display_name": "CSS Class for Course Reruns",
                        "help": "Allows courses to share the same css class across runs even if they have different numbers.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "disable_progress_graph": {
                        "value": false,
                        "display_name": "Disable Progress Graph",
                        "help": "Enter true or false. If true, students cannot view the progress graph.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "discussion_link": {
                        "value": null,
                        "display_name": "Discussion Forum External Link",
                        "help": "Allows specification of an external link to replace discussion forums.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "display_coursenumber": {
                        "value": "",
                        "display_name": "Course Number Display String",
                        "help": "Enter the course number that you want to appear in the course. This setting overrides the course number that you entered when you created the course. To use the course number that you entered when you created the course, enter null.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "display_organization": {
                        "value": null,
                        "display_name": "Course Organization Display String",
                        "help": "Enter the course organization that you want to appear in the course. This setting overrides the organization that you entered when you created the course. To use the organization that you entered when you created the course, enter null.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "due_date_display_format": {
                        "value": null,
                        "display_name": "Due Date Display Format",
                        "help": "Enter the format for due dates. The default is Mon DD, YYYY. Enter \"%m-%d-%Y\" for MM-DD-YYYY, \"%d-%m-%Y\" for DD-MM-YYYY, \"%Y-%m-%d\" for YYYY-MM-DD, or \"%Y-%d-%m\" for YYYY-DD-MM.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "enable_proctored_exams": {
                        "value": false,
                        "display_name": "Enable Proctored Exams",
                        "help": "Enter true or false. If this value is true, proctored exams are enabled in your course. Note that enabling proctored exams will also enable timed exams.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "enable_subsection_gating": {
                        "value": false,
                        "display_name": "Enable Subsection Prerequisites",
                        "help": "Enter true or false. If this value is true, you can hide a subsection until learners earn a minimum score in another, prerequisite subsection.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "enable_timed_exams": {
                        "value": true,
                        "display_name": "Enable Timed Exams",
                        "help": "Enter true or false. If this value is true, timed exams are enabled in your course. Regardless of this setting, timed exams are enabled if Enable Proctored Exams is set to true.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "end_of_course_survey_url": {
                        "value": null,
                        "display_name": "Course Survey URL",
                        "help": "Enter the URL for the end-of-course survey. If your course does not have a survey, enter null.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "enrollment_domain": {
                        "value": null,
                        "display_name": "External Login Domain",
                        "help": "Enter the external login method students can use for the course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "hide_progress_tab": {
                        "value": null,
                        "display_name": "Hide Progress Tab",
                        "help": "Allows hiding of the progress tab.",
                        "deprecated": true,
                        "hide_on_enabled_publisher": false
                    },
                    "html_textbooks": {
                        "value": [],
                        "display_name": "HTML Textbooks",
                        "help": "For HTML textbooks that appear as separate tabs in the course, enter the name of the tab (usually the title of the book) as well as the URLs and titles of each chapter in the book.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "instructor_info": {
                        "value": {
                            "instructors": []
                        },
                        "display_name": "Course Instructor",
                        "help": "Enter the details for Course Instructor",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "invitation_only": {
                        "value": false,
                        "display_name": "Invitation Only",
                        "help": "Whether to restrict enrollment to invitation by the course staff.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "is_new": {
                        "value": null,
                        "display_name": "Course Is New",
                        "help": "Enter true or false. If true, the course appears in the list of new courses, and a New! badge temporarily appears next to the course image.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "learning_info": {
                        "value": [],
                        "display_name": "Course Learning Information",
                        "help": "Specify what student can learn from the course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "lti_passports": {
                        "value": [],
                        "display_name": "LTI Passports",
                        "help": "Enter the passports for course LTI tools in the following format: \"id:client_key:client_secret\".",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "max_student_enrollments_allowed": {
                        "value": null,
                        "display_name": "Course Maximum Student Enrollment",
                        "help": "Enter the maximum number of students that can enroll in the course. To allow an unlimited number of students, enter null.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "mobile_available": {
                        "value": false,
                        "display_name": "Mobile Course Available",
                        "help": "Enter true or false. If true, the course will be available to mobile devices.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "no_grade": {
                        "value": false,
                        "display_name": "Course Not Graded",
                        "help": "Enter true or false. If true, the course will not be graded.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "other_course_settings": {
                        "value": {},
                        "display_name": "Other Course Settings",
                        "help": "Any additional information about the course that the platform needs or that allows integration with external systems such as CRM software. Enter a dictionary of values in JSON format, such as { \"my_custom_setting\": \"value\", \"other_setting\": \"value\" }",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "proctoring_provider": {
                        "value": "null",
                        "display_name": "Proctoring Provider",
                        "help": "Enter the proctoring provider you want to use for this course run. Choose from the following options: lti_external, null.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "remote_gradebook": {
                        "value": {},
                        "display_name": "Remote Gradebook",
                        "help": "Enter the remote gradebook mapping. Only use this setting when REMOTE_GRADEBOOK_URL has been specified.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "show_calculator": {
                        "value": false,
                        "display_name": "Show Calculator",
                        "help": "Enter true or false. When true, students can see the calculator in the course.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "teams_configuration": {
                        "value": {},
                        "display_name": "Teams Configuration",
                        "help": "Configure team sets, limit team sizes, and set visibility settings using JSON. See <a target=\"&#95;blank\" href=\"https://edx.readthedocs.io/projects/edx-partner-course-staff/en/latest/course_features/teams/teams_setup.html#enable-and-configure-teams\">teams configuration documentation</a> for help and examples.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    },
                    "video_thumbnail_image": {
                        "value": "images_course_image.jpg",
                        "display_name": "Course Video Thumbnail Image",
                        "help": "Edit the name of the video thumbnail image file. You can set the video thumbnail image on the Settings & Details page.",
                        "deprecated": false,
                        "hide_on_enabled_publisher": false
                    }
                }
                const scormSettingsUpdate = await setScormCreationAPI(courseKey, scormData, {
                    'Accept':"application/json, text/javascript, */*; q=0.01"
                })
                if(!(Array.isArray(scormSettingsUpdate?.advanced_modules?.value) && scormSettingsUpdate?.advanced_modules?.value?.length > 0)){
                    await deleteCourseAPI(courseKey)
                    setSubmitting(false)
                    setNotification({
                        msg: "An error occurred during scorm settings update.",
                        success: false
                    })
                    return
                }
                const scormBlockCreationData = await createCourseSection({
                    "category": "scorm",
                    "parent_locator": unitData?.locator
                })
                if(!scormBlockCreationData?.locator){
                    await deleteCourseAPI(courseKey)
                    setSubmitting(false)
                    setNotification({
                        msg: "An error occurred during scorm block creation.",
                        success: false
                    })
                    return
                }
                const scormFormData = new FormData()
                scormFormData.append('file', fields.scorm)
                scormFormData.append('display_name', "Scorm module")
                scormFormData.append('has_score', 1)
                scormFormData.append('enable_navigation_menu', 1)
                scormFormData.append('weight', 1)
                scormFormData.append('width', "")
                scormFormData.append('height', 450)
                scormFormData.append('navigation_menu_width', "")
                scormFormData.append('popup_on_launch', 0)
                scormFormData.append('scorm_s3_path', "")
                const scormFileUploadData = await createCourseExtraSection(`${scormBlockCreationData?.locator}/handler/studio_submit`, scormFormData, true, {
                    "Accept":'application/json, text/javascript, */*; q=0.01'
                })
                if(scormFileUploadData?.result!=="success"){
                    await deleteCourseAPI(courseKey)
                    setSubmitting(false)
                    setNotification({
                        msg: "An error occurred during scorm file upload.",
                        success: false
                    })
                    return
                }
                break;
            case "video_file" :
                const videoFormData = new FormData()
                videoFormData.append("file", fields?.video_file)
               const videoFileUpload = await videoFileUploadAPI(courseKey, videoFormData)
                const formattedVideoFileUpload =  isJSON(videoFileUpload) ? JSON.parse(videoFileUpload) : {}
                if(!formattedVideoFileUpload?.file_url){
                    await deleteCourseAPI(courseKey)
                    setSubmitting(false)
                    setNotification({
                        msg: "An error occurred during the video file upload.",
                        success: false
                    })
                    return
                }
                videoURL = formattedVideoFileUpload?.file_url
                //Absence of break here isn't an error. A continuity here when content type is video file
            default:
                const videoData = await createCourseSection({
                    "category": "video",
                    "type": "video",
                    "parent_locator": unitData?.locator
                })
                if(!videoData?.locator){
                    await deleteCourseAPI(courseKey)
                    setSubmitting(false)
                    setNotification({
                        msg: "An error occurred during course video section creation.",
                        success: false
                    })
                    return
                }
                const isYoutubeVideo = isYouTubeVideoUrl(videoURL)
                const videoDataUpload = await createCourseExtraSection(videoData?.locator, {
                    "id": videoData?.locator,
                    "display_name": "Video",
                    "category": "video",
                    "metadata": {
                        "display_name": "Video",
                        "html5_sources": !isYoutubeVideo ? [videoURL] :  [],
                        "edx_video_id": "",
                        "youtube_id_1_0": isYoutubeVideo ? getYouTubeVideoId(videoURL) : ""
                    },
                    "studio_url": null,
                    "child_info": null,
                    "ancestor_info": null,
                    "edited_on": null,
                    "edited_by": null,
                    "published": null,
                    "published_on": null,
                    "published_by": null,
                    "has_children": true,
                    "has_changes": null,
                    "visibility_state": null,
                    "released_to_students": null,
                    "release_date": null,
                    "release_date_from": null,
                    "currently_visible_to_students": null,
                    "due_date": null,
                    "format": null,
                    "course_graders": null,
                    "graded": null,
                    "start": null,
                    "due": null,
                    "has_explicit_staff_lock": null,
                    "ancestor_has_staff_lock": null,
                    "staff_lock_from": null,
                    "staff_only_message": null,
                    "has_partition_group_components": null,
                    "actions": null,
                    "is_header_visible": null,
                    "explanatory_message": null,
                    "group_access": null,
                    "user_partitions": null,
                    "highlights": [],
                    "highlights_enabled": false,
                    "highlights_enabled_for_messaging": false,
                    "highlights_preview_only": true,
                    "highlights_doc_url": "",
                    "courseKey": courseKey
                })
                if(!videoDataUpload?.id){
                    await deleteCourseAPI(courseKey)
                    setSubmitting(false)
                    setNotification({
                        msg: "An error occurred during the course video url update.",
                        success: false
                    })
                    return
                }
                break;
        }
        const publishUnitData = await createCourseExtraSection(unitData?.locator, {
            "publish": "make_public"
        })
        if(!publishUnitData?.id){
            await deleteCourseAPI(courseKey)
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during the course publishing process.",
                success: false
            })
            return
        }
        setSubmitting(false)
        setFields(defaultFields)
        setImageCardUploadResponse(defaultStudioImageUploadResponse)
        setScormFileUploadResponse(defaultStudioImageUploadResponse)
        setVideoFileUploadResponse(defaultVideoFileUploadResponse)
        setCurrentTab(TABS[0])
        navigate(`/studio/authoring/courses/${courseKey}`, { replace: true })
        setNotification({
            msg: `${fields?.display_name} course created successfully.`,
            success: true
        })
    }

    return {
        submitting,
        fields,
        setFields,
        imageCardUploadResponse,
        setImageCardUploadResponse,
        handleFormSubmit,
        notificationRef,
        notification,
        TABS,
        currentTab,
        isLastTab,
        goToNextTab,
        setCurrentTab,
        switchContentType,
        setScormFileUploadResponse,
        scormFileUploadResponse,
        setVideoFileUploadResponse,
        videoFileUploadResponse
    }
};

export default useCourseCreation;