import {getTenant, isCurrentUserOnlyDepartmentManager} from "../helpers";
import {getUserId, getUserName, isJSON} from "@iblai/ibl-web-react-common";

export const saveSuggestionAssignmentAPI = ({contentType="course", data, isGroup=false}) => {
    return new Promise((_resolve)=>{
        const currentTenant = getTenant()
        const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/manage/${isGroup ? "group/" : ""}`
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                ...data,
                platform_key:currentTenant,
                ...(isCurrentUserOnlyDepartmentManager() ? {
                    department_mode:true
                } : {}),
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            _resolve(resp.ok)
        }).catch(_resolve);
    })
}

export const saveLearnerCredentialsAssignmentAPI = (data, assignmentType="users") => {
    const currentTenant = localStorage.getItem("current_tenant")
    const platformOrg = isJSON(currentTenant) ? JSON.parse(currentTenant)?.org : null
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/credentials/orgs/${platformOrg}/users/${getUserId()}/assignments/${assignmentType}/`
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            ...data,
            ...(isCurrentUserOnlyDepartmentManager() ? {
                department_mode:true
            } : {})
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => (resp.ok)).catch(false);
}

export const saveLearnerSkillsAssignmentAPI = (data) => {
    const currentTenant = getTenant()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/milestones/skill_points/platform`
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            ...data,
            platform_key:currentTenant,
            ...(isCurrentUserOnlyDepartmentManager() ? {
                department_mode:true
            } : {})
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => (resp.ok)).catch(false);
}

export const saveGroupsSkillsAssignmentAPI = (data) => {
    const currentTenant = getTenant()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/milestones/skill_points/platform/group`
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            ...data,
            platform_key:currentTenant,
            ...(isCurrentUserOnlyDepartmentManager() ? {
                department_mode:true
            } : {})
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => (resp.ok)).catch(false);
}

export const deleteSkillsAssignmentAPI = (skillPointID) => {
    const currentTenant = getTenant()
    const params = new URLSearchParams({
        platform_key:currentTenant,
        skill_point_id:skillPointID,
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:true
        } : {})
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/milestones/skill_points/platform/?${params}`
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => (resp.ok)).catch(()=>false);
}

export const saveBulkSuggestionsAssignments = ({contentType="course", data}) => {
    return new Promise((_resolve)=>{
        const currentTenant = getTenant()
        const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/manage/bulk`
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                suggestion_data:data,
                platform_key:currentTenant,
                ...(isCurrentUserOnlyDepartmentManager() ? {
                    department_mode:true
                } : {}),
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            _resolve(resp.ok)
        }).catch(_resolve);
    })
}

export const getLearnersTeamsAssignmentList = (contentType="course", queryParam={},successCallback, errorCallback, isGroup=false) =>{
    const param = new URLSearchParams({...queryParam,
        platform_key:getTenant(),
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:1
        } : {}),
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/manage/${isGroup ? "group/" : ""}?${param}`
    fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        resp.json().then(successCallback).catch(errorCallback);
    }).catch(errorCallback);
}

export const getCredentialsLearnersTeamsAssignmentList = (queryParam={}, assignmentType="users", successCallback, errorCallback) =>{
    const currentTenant = localStorage.getItem("current_tenant")
    const platformOrg = isJSON(currentTenant) ? JSON.parse(currentTenant)?.org : null
    const param = new URLSearchParams(queryParam).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/credentials/orgs/${platformOrg}/users/${getUserId()}/assignments/${assignmentType}/?${param}`
    fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        resp.json().then(successCallback).catch(errorCallback);
    }).catch(errorCallback);
}

export const getAllCredentials = (search="", successCallback, errorCallback) =>{
    const currentTenant = localStorage.getItem("current_tenant")
    const platformOrg = isJSON(currentTenant) ? JSON.parse(currentTenant)?.org : null
    const param = new URLSearchParams({
        ...(search ? {
            search
        } : {})
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/credentials/orgs/${platformOrg}/users/${getUserId()}/?${param}`
    fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        resp.json().then(successCallback).catch(errorCallback);
    }).catch(errorCallback);
}

export const getSkillsLearnersTeamsAssignmentList = (queryParam={},successCallback, errorCallback, isGroup=false) =>{
    const param = new URLSearchParams({...queryParam,
        platform_key:getTenant(),
        ...(isCurrentUserOnlyDepartmentManager() ? {
            department_mode:1
        } : {})
    }).toString()
    const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/milestones/skill_points/platform/${isGroup ? "group/" :""}?${param}`
    fetch(url, {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + localStorage.getItem('dm_token'),
        },
    }).then((resp) => {
        resp.json().then(successCallback).catch(errorCallback);
    }).catch(errorCallback);
}


export const deleteTeamsAssignments = async (assignmentID, contentType="course", isGroup=false) =>{
    return new Promise((_resolve)=>{
        const currentTenant = getTenant()
        const param = new URLSearchParams({
            suggestion_id:assignmentID,
            platform_key:currentTenant,
            ...(isCurrentUserOnlyDepartmentManager() ? {
                department_mode:1
            } : {}),
        }).toString()
        const url = process.env.REACT_APP_IBL_DM_URL + `/api/catalog/suggestions/${contentType}/manage/${isGroup ? "group/" : ""}?`+param
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            _resolve(resp.ok)
        }).catch(_resolve);
    })
}

export const deleteCredentialsTeamsAssignments = async (assignmentID) =>{
    return new Promise((_resolve)=>{
        const currentTenant = localStorage.getItem("current_tenant")
        const platformOrg = isJSON(currentTenant) ? JSON.parse(currentTenant)?.org : null
        const url = process.env.REACT_APP_IBL_DM_URL + `/api/credentials/orgs/${platformOrg}/users/${getUserId()}/assignments/${assignmentID}`
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then((resp) => {
            _resolve(resp.ok)
        }).catch(_resolve);
    })
}