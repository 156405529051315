import React, {useContext, useEffect, useState} from 'react';
import Downloadreportcard from "../../../components/Downloadreportcard/Downloadreportcard";
import useDownloads from "../../../hooks/useDownloads";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import './DownloadsViewContent.css'
import {useDebounce} from "../../../hooks/useDebounce";
import {getDownloadsListAPI} from "../../../hooks/utils/reports";
import {isCurrentUserOnlyDepartmentManager} from "../../../hooks/helpers";


const DownloadsViewContent = () => {

    const { appData, setAppData } = useContext(AppDataContext);

    const [downloadsData, setDownlaodsData] = useState([])
    const [downloaded, setDownloaded] = useState(false)
    const [downloadsLoadedOnce, setDownloadsLoadedOnce] = useState(false)

    const handleLoadData = () => {
        if(!downloadsLoadedOnce || !!appData?.downloadsRefetching){
            getDownloadsListAPI((data) => {
                //TODO ai-mentor-chat-history temporarily filtered out
                const updatedData = isCurrentUserOnlyDepartmentManager() ? data?.filter((pre)=>pre.report_name!=="ai-mentor-chat-history") : data
                setDownlaodsData(updatedData);
                setDownloaded(true);
                setDownloadsLoadedOnce(true);
                setAppData({
                    ...appData,
                    downloadsRefetching:false
                })
            });
        }
    }

    useDebounce(handleLoadData, 300, [appData?.downloadsRefetching])


    return (
        <>

            <div className="div-block-253" no-mapping="">
                <div className="div-block-205">
                    <div className="div-block-255"></div>
                    <div className="text-block-154">DOWNLOADS</div>
                </div>
            </div>
            <div className="d_section" no-mapping="">
                <div className="div-block-253">
                    <div className="text-block-138 sm">
                        Overview
                        <br/>
                    </div>
                </div>
            </div>
            {
                !downloaded ? <IBLSpinLoader containerHeight={"200px"} size={30} color={"#FFF"} /> :
                    (
                        Array.isArray(downloadsData) && downloadsData.length > 0 ?
                            downloadsData.map((download)=>(
                                <Downloadreportcard {...download}></Downloadreportcard>
                            ))
                            :
                            <EmptyTableDataInfographic/>

                    )
            }

        </>
    );
};

export default DownloadsViewContent;