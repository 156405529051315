import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom"
import Async from "react-select/async";
import useTeamsAssignments from "../../../hooks/useTeamsAssignments";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import TeamsUsersAssignmentTabContent
    from "../TeamsAssignmentCreationParentViewContent/modules/TeamsUsersAssignmentTabContent/TeamsUsersAssignmentTabContent";
import TeamsAssignmentTabContent
    from "../TeamsAssignmentCreationParentViewContent/modules/TeamsAssignmentTabContent/TeamsAssignmentTabContent";
import AsyncCreatableSelect from "react-select/async-creatable";

const TeamsAssignmentSkillsCreationViewContent = () => {
    const navigate = useNavigate()
    const {setCurrentTab, currentTab} = useOutletContext()
    const {
        getSkillsForSelectDropdown,
        handleSaveSkillsLearnersAssignment,
        handleSaveTeamsAssignmentSuggestions,
        handleSaveSkillsGroupsAssignment
    } = useTeamsAssignments()
    const {setNotification} = useContext(AppDataContext)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [submitting, setSubmitting] = useState(false)
    //SUB TABS HANDLER
    const LEARNER_MODE = "learners"
    const TEAM_MODE = "teams"
    const BULK_MODE = "bulk"
    const ASSIGN_MODES = [
        {
            label: "Learners",
            slug: "learners"
        },
        {
            label: "Teams",
            slug: "teams"
        },
        {
            label: "Bulk",
            slug: "bulk"
        },
    ]
    const [currentAssignMode, setCurrentAssignMode] = useState(ASSIGN_MODES[0].slug)

    //FIELDS HANDLING
    const defaultFields = {
        skills: [],
        content: currentAssignMode,
        learners: [],
        teams: [],
        bulk: ""
    }
    const [fields, setFields] = useState(defaultFields)

    const [focusedSkill, setFocusedSkill] = useState(null)

    const [focusedSkillPoints, setFocusedSkillPoints] = useState("")

    const handleFocusedSkillPointChange = (e) => {
        setFocusedSkillPoints(e.target.value)
    }

    useEffect(() => {
        setCurrentTab('skills')
    }, []);

    //COURSE DROPDOWN
    const handleSkillChange = (choice) => {
        setFields({
            ...fields,
            skills: choice,
        });
    };

    const skillPromiseOptions = (inputValue) => {
        return new Promise((resolve) => {
            getSkillsForSelectDropdown(inputValue, (data) => {
                const result = data?.results;
                if (Array.isArray(result) && result.length > 0) {
                    resolve(
                        result
                            .map((skill) => {
                                return {
                                    value: skill?.data?.id,
                                    label: skill?.data?.name,
                                };
                            })
                    );
                } else {
                    resolve([]);
                }
            });
        });
    };

    const handleSkillsPointUpdate = () => {
        if(!focusedSkillPoints) {
            setNotification({
                msg:"Set a valid skills point value.",
                success:false
            })
            return;
        }
        //const index = fields.skills.findIndex((pre)=>pre.value===focusedSkill?.value)
        const updatedSkills = [
            ...fields.skills,
            {
                label:`${focusedSkill.label} (${focusedSkillPoints})`,
                value: focusedSkill.value,
                point:focusedSkillPoints,
                realLabel:focusedSkill.label
            }
        ]
        handleSkillChange(updatedSkills)
        setFocusedSkill(null)
        setFocusedSkillPoints(0)
    }

    const handleSubmit = async () => {
        if (submitting) {
            return
        }
        if (fields.skills.length === 0 || (currentAssignMode === LEARNER_MODE && selectedUsers.length === 0) || (currentAssignMode === TEAM_MODE && selectedTeams.length === 0)) {
            setNotification({
                msg: `Fill out all required fields.`,
                success: false
            })
            return
        }
        setSubmitting(true)
        let response
        let skillsPointsData = {}
        fields.skills.forEach((_skill) => {
            skillsPointsData[_skill?.realLabel]=_skill.point
        })
        switch (currentAssignMode) {
            case TEAM_MODE:
                response = await handleSaveSkillsGroupsAssignment({
                    skills:skillsPointsData,
                    teams: [...selectedTeams]
                })
                break;
            default:
                response = await handleSaveSkillsLearnersAssignment({
                    learners:[...selectedUsers],
                    skills:skillsPointsData
                })
        }
        setSubmitting(false)
        if (!response) {
            setNotification({
                msg: `Skill assignment failed. Please try again.`,
                success: false
            })
            return
        }
        setNotification({
            msg: `Skill assignment successful.`,
            success: true
        })
        setFields(defaultFields)
        setSelectedTeams([])
        setSelectedUsers([])
    }
    return (
        <>
            <div data-w-tab="Platform" className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                    <div className="form-block-7 w-form">
                        <form id="email-form-5" name="email-form-5" data-name="Email Form 5"
                              method="get" data-wf-page-id="67a0b1858f35b36230d3e1a3"
                              data-wf-element-id="f013830d-d35f-ead7-aced-31486906216d"><label
                            htmlFor="field-5" className="text-block-139-bd-f-soc">Skills *</label>
                            <Async
                                isMulti
                                cacheOptions
                                classNamePrefix={'react-select'}
                                defaultOptions
                                loadOptions={skillPromiseOptions}
                                value={fields?.skills}
                                options={fields?.skills}
                                onChange={(choice) => {
                                    const totalElements = choice.length
                                    if(totalElements > fields.skills.length) {
                                        setFocusedSkill(choice[totalElements-1])
                                    }else{
                                        handleSkillChange(choice)
                                    }

                                }}
                                isSearchable={true}
                                placeholder={"Search for skills..."}
                            />
                            <label htmlFor="name-3" className="text-block-139-bd-f-soc">Assign Mode
                                *</label>
                            <div data-current="Individual" data-easing="ease" data-duration-in={300}
                                 data-duration-out={100} className="tabs-2 w-tabs">
                                <div className="tabs-menu-2 w-tab-menu">
                                    <a onClick={e => {
                                        e.preventDefault()
                                        setCurrentAssignMode(LEARNER_MODE)
                                    }} href={"#"} data-w-tab="Individual"
                                       className={`invite-mode-tab-link w-inline-block w-tab-link ${currentAssignMode === LEARNER_MODE ? "w--current" : ""} `}>
                                        <div className="w-layout-hflex flex-block-13"><img
                                            loading="lazy" src="/images/user-12.svg" alt
                                            className="image-142"/></div>
                                        <h5 className="heading-5 capitalize">{LEARNER_MODE}</h5>
                                    </a>
                                    <a data-w-tab="Bulk" onClick={e => {
                                        e.preventDefault()
                                        setCurrentAssignMode(TEAM_MODE)
                                    }} href={"#"}
                                       className={`invite-mode-tab-link w-inline-block w-tab-link ${currentAssignMode === TEAM_MODE ? "w--current" : ""} `}>
                                        <div className="w-layout-hflex flex-block-13"><img
                                            loading="lazy" src="/images/users-4.svg" alt
                                            className="image-142"/></div>
                                        <h5 className="heading-5 capitalize">{TEAM_MODE}</h5>
                                    </a>
                                    {/*<a data-w-tab="Bulk 2" onClick={e => {
                                    e.preventDefault()
                                    setCurrentAssignMode(BULK_MODE)
                                }} href={"#"}
                                   className={`invite-mode-tab-link w-inline-block w-tab-link ${currentAssignMode===BULK_MODE ? "w--current" : ""} `}>
                                    <div className="w-layout-hflex flex-block-13"><img
                                        loading="lazy" src="/images/file-stack.svg" alt
                                        className="image-142"/></div>
                                    <h5 className="heading-5 capitalize">{BULK_MODE}</h5>
                                </a>*/}
                                </div>
                                <div className="tabs-content w-tab-content">
                                    {
                                        currentAssignMode === LEARNER_MODE && <TeamsUsersAssignmentTabContent {...{
                                            selectedUsers, setSelectedUsers
                                        }} />
                                    }
                                    {
                                        currentAssignMode === TEAM_MODE && (
                                            <TeamsAssignmentTabContent {...{
                                                selectedTeams, setSelectedTeams
                                            }} />
                                        )
                                    }
                                    {
                                        currentAssignMode === BULK_MODE && (
                                            <div data-w-tab="Bulk 2" className="w-tab-pane w--tab-active">
                                                <div className="w-layout-hflex flex-block-31"><label
                                                    htmlFor="name-3" className="text-block-139-bd-f-soc">CSV
                                                    file upload *</label>
                                                    <a href="#" className="link-block-25 w-inline-block"><img
                                                        loading="lazy" src="/images/paperclip-1.svg" alt
                                                        className="image-151"/>
                                                        <div>usa-team.csv</div>
                                                    </a>
                                                </div>
                                                <div
                                                    className="div-block-207 first active follow-user redeem-btn mg-l16 no-mg-left">
                                                    <div className="text-block-111 red-text cf">Upload</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-layout-hflex flex-block-37">
                <div className="div-block-308">
                    <a onClick={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }} href="#" className="link-block-22 w-inline-block ">
                        <div data-w-id="248c8885-eeb2-71e0-8144-2591929c8295"
                             className={`div-block-207 first active follow-user assignment-submit-btn ${submitting ? "disabled" : ""}`}>
                            <div className="text-block-111">Add Assignment</div>
                            {submitting &&
                                <span style={{marginLeft: "10px"}}><IBLSpinLoader size={15} color={"#FFF"}/></span>}
                        </div>
                    </a>
                </div>
            </div>
            {
                focusedSkill && (
                    <div
                        style={{display: 'flex'}}
                        react-component="InvitePopup"
                        className="popup_div-nm_popup assign-license-popup"
                    >
                        <div className="popup_cont-sk">
                            <div className="hf_popup">
                                <div className="div-block-147 new_msg">
                                    <div className="text-block-135 mg-0">Set {focusedSkill?.label} skill point</div>
                                </div>
                                <div className="div-block-228">
                                    <div className="form-block-7 w-form">
                                        <form
                                            id="email-form-5"
                                            name="email-form-5"
                                            data-name="Email Form 5"
                                            method="get"
                                            data-wf-page-id="66e0598d95b3c617324f4850"
                                            data-wf-element-id="04b212b6-6908-556e-c3c3-d1f2508be1ea"
                                        >
                                            <label
                                                htmlFor="field-5"
                                                className="text-block-139-bd-f-soc"
                                            >
                                                Skill point
                                            </label>
                                            <input
                                                className="text-field-3 w-input"
                                                name="courseId"
                                                data-name="Course ID"
                                                placeholder="Enter skill's point"
                                                type="number"
                                                min={0}
                                                //id="course-id"
                                                //style={{backgroundColor: 'transparent'}}
                                                value={focusedSkillPoints}
                                                onChange={handleFocusedSkillPointChange}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className="div-block-147 new_msg">
                                    <div className="div-block-264 f-w">
                                        <div
                                            onClick={() => handleSkillsPointUpdate()}
                                            className="div-block-207 first active follow-user redeem-btn mg-l16"
                                        >
                                            <div className="text-block-111 red-text cf">
                                                Update
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>

    )
        ;
};

export default TeamsAssignmentSkillsCreationViewContent;